import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service.js';
import { injectMapConsent } from '../../maps-consent/1.0.0/mojo.js';
import { hideOverlay, TuiOverlay } from '../../overlay.service.js';
import { createHTMLNode, loadScripts } from '../../util.js';

const language = elchspuckeTuicomService.getPageLanguage();

const SELECTORS = {
    list: 'tui-ao-search-result',
    toggleBtn: '.list-switcher'
}

class ListSwitcher {
    constructor() {
        this.listContainer = document.querySelector(SELECTORS.list)
        this.toggleBtn = document.querySelector(SELECTORS.toggleBtn)
        this.toggleBtn.addEventListener('click', () => this.toggleList())
    }

    toggleList() {
        document.querySelector('.tui-search-result-map-overlay').classList.remove('move-view-button')
        // show the tuicom list instead of the search-result list
        hideOverlay()
    }
}

const listIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="" viewBox="0 0 120 120">\n' +
    '<g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="8">\n' +
    '<line x1="45" x2="100" y1="35" y2="35"/>\n' +
    '<line x1="45" x2="100" y1="60" y2="60"/>\n' +
    '<line x1="45" x2="100" y1="85" y2="85"/>\n' +
    '<path d="M25,81 C27.209139,81 29,82.790861 29,85 C29,87.209139 27.209139,89 25,89 C22.790861,89 21,87.209139 21,85 C21,82.790861 22.790861,81 25,81 Z M25,56 C27.209139,56 29,57.790861 29,60 C29,62.209139 27.209139,64 25,64 C22.790861,64 21,62.209139 21,60 C21,57.790861 22.790861,56 25,56 Z M25,31 C27.209139,31 29,32.790861 29,35 C29,37.209139 27.209139,39 25,39 C22.790861,39 21,37.209139 21,35 C21,32.790861 22.790861,31 25,31 Z"/>\n'
'</g>\n' +
    '</svg>';
'</svg>';
'</svg>';
'</svg>';
'</svg>';
'</svg>';
'</svg>';

/**
 * Creates acco only search result element
 * @return {HTMLElement} acco only search result element
 */
function buildSearchResultElement(cdnUrl, market, locale) {

    const searchResultElementModule = document.createElement('script');
    searchResultElementModule.type = 'module';
    searchResultElementModule.src = `${cdnUrl}/search-result/current/search-result.esm.js`;
    searchResultElementModule.async = true;
    const searchResultElementNoModule = document.createElement('script');
    searchResultElementNoModule.noModule = true;
    searchResultElementNoModule.src = `${cdnUrl}/search-result/current/search-result.js`;
    searchResultElementNoModule.async = true;

    document.head.appendChild(searchResultElementModule);
    document.head.appendChild(searchResultElementNoModule);

    let searchResult = createHTMLNode('tui-ao-search-result');
    searchResult.setAttribute('locale', locale);
    searchResult.setAttribute('market', market);
    searchResult.setAttribute('category-icon', 'sun');

    return searchResult;
}

/**
 * Creates acco only search map element
 * @return {HTMLElement} acco only search map element
 */
function buildMapElement(cdnUrl, market, locale) {

    const searchMapElementModule = document.createElement('script');
    searchMapElementModule.type = 'module';
    searchMapElementModule.src = `${cdnUrl}/search-map/current/search-map.esm.js`;
    searchMapElementModule.async = true;
    const searchMapElementNoModule = document.createElement('script');
    searchMapElementNoModule.noModule = true;
    searchMapElementNoModule.src = `${cdnUrl}/search-map/current/search-map.js`;
    searchMapElementNoModule.async = true;

    document.head.appendChild(searchMapElementModule);
    document.head.appendChild(searchMapElementNoModule);

    let searchMap = createHTMLNode('tui-search-map', '', '', [
        { key: 'locale', value: locale },
        { key: 'market', value: market },
        { key: 'api-key', value: 'AIzaSyBwXTpsKTvJ6JFXOEJRrU9qNaup3HNAFpU' }, // nosemgrep
        { key: 'category-icon', value: 'sun' },
        { key: 'data-theme', value: 'tui-light' }
    ]);

    return searchMap;

}

/**
 * Maps travellers for acco only map travellers
 * @param {object} filters ibe state filters
 * @return {array} merged travellers array
 */
function getTravellers(filters) {

    return filters.travellers.map(room => {
        let rooms = [];
        const adults = room && room.adults
            ? Array.from(Array(room.adults).keys())
            : [];
        const children = room && room.children
            ? room.children
            : [];

        const travellers = [
            ...adults.map(() => { return 28 }),
            ...children.map((child) => { return child })
        ].join();
        rooms.push(travellers);
        return rooms;
    })
}

const mapTenantToMarket = (tenant) => {

    switch (tenant) {
        case 'TUICOM':
            return 'de';
        case 'TUIAT':
            return 'at';
        case 'TUICH':
            return 'ch';
        default:
            return 'de';
    }
}

const getLocale = (config) => {
    let locale = config.getLanguage().replace('_', '-');
    const pageLanguage = elchspuckeTuicomService.getPageLanguage();
    return locale.replace(/^.{2}/g, pageLanguage);
}

const getLocation = (ibeState) => {

    if (ibeState) {
        if (ibeState.filters && ibeState.filters.cities && ibeState.filters.cities.length) {
            return { cities: ibeState.filters.cities };
        }
        if (ibeState.region && ibeState.region.id) {
            return { regionId: ibeState.region.id };
        }
        return { regionId: [] };
    }
};

const initializeSearchMap = (ibeState, hotelForCoordinates) => {

    const ibeSearchParams = elchspuckeTuicomService.mapPTH4SearchParams(ibeState.filters);

    let center = {};
    if (hotelForCoordinates) {
        center = {
            lat: hotelForCoordinates.latitude,
            lng: hotelForCoordinates.longitude
        }
    }

    var searchData = {
        arrivalDate: ibeSearchParams.startDate,
        departureDate: ibeSearchParams.endDate,
        nights: ibeSearchParams.durations.join() || '7',
        ages: getTravellers(ibeState.filters).join('|'),
        airports: ibeSearchParams.departureAirportCodes.join(),
        searchScope: ibeState.filters.searchScope,
        legacy: true,
        center
    }

    const location = getLocation(ibeState);
    const locationKey = Object.keys(location)[0];
    const locationValue = location[locationKey];
    searchData[locationKey] = locationValue;

    // Do a search
    customElements.whenDefined('tui-ao-search-result').then(() => {
        document.dispatchEvent(new CustomEvent('tui.ao.search-result.search-update', { detail: searchData }));
    });
    // Set the maps viewport
    customElements.whenDefined('tui-search-map').then(() => {
 
        document.dispatchEvent(new CustomEvent('tui.search-ui.map-viewport', {
            detail: {
                //locationId: searchData.locationId,
                center: searchData.center,
                ne: searchData.ne,
                sw: searchData.sw
            }
        }));

        const searchMap = document.querySelector('tui-search-map');
        searchMap.loadMap(searchData);
    });
}

function builContentNode(searchMap, searchResult) {
    // create list switcher element
    const listSwitcher = createHTMLNode('span', 'list-switcher');
    const listSwitcherInner = createHTMLNode('span', 'list-switcher-inner');
    const listSwitcherText = createHTMLNode('span', 'list-switcher-text');
    const text = language === 'fr' ? 'Afficher la liste' : 'Liste anzeigen';
    const listSwitcherTextNode = document.createTextNode(text);

    const listIconNode = createHTMLNode('span');
    listIconNode.innerHTML = listIcon;

    listSwitcherText.appendChild(listSwitcherTextNode);
    listSwitcherInner.appendChild(listIconNode);
    listSwitcherInner.appendChild(listSwitcherText);
    listSwitcher.appendChild(listSwitcherInner);

    let searchMapContainer = createHTMLNode('div', 'search-map-container');
    searchMapContainer.appendChild(searchMap);

    let searchAndMapContainer = createHTMLNode('div', 'full-width-container search-and-map-container');
    searchAndMapContainer.appendChild(searchResult);
    searchAndMapContainer.appendChild(searchMapContainer);
    searchAndMapContainer.appendChild(listSwitcher);
    return searchAndMapContainer;
}

window.initMap = async () => {
    await customElements.whenDefined('tui-nordic-search-panel').then(() => {
      document.dispatchEvent(new CustomEvent('tui.ao.google.api.ready'));
    });
  };

let overlay = null;
/**
 * Injects overlay with acco only map component into body
 */
export function injectSearchResultMapComponent(ibeState, hotelForCoordinates, config) {
    // nosemgrep
    loadScripts(['https://maps.googleapis.com/maps/api/js?libraries=places,marker&v=beta&key=AIzaSyBwXTpsKTvJ6JFXOEJRrU9qNaup3HNAFpU&callback=initMap'], { type: '' })
        .then(() => {
            overlay && overlay.destroy();
            const cdnUrl = config.getAWSCdnUrlAccoOnly();
            const market = mapTenantToMarket(config.getTenant());
            const locale = getLocale(config);
            const searchResultElement = buildSearchResultElement(cdnUrl, market, locale);
            const mapElement = buildMapElement(cdnUrl, market, locale);
            const contentNode = builContentNode(mapElement, searchResultElement);
            elchspuckeTuicomService.insertAsFirstChildIfExists(contentNode, document.getElementById('tui-maps-consent'))
            overlay = new TuiOverlay({ overlayId: 'tui-search-result-map-overlay', contentNode: contentNode });
            overlay.show();
            if (document.querySelector('.search-map-container')) {
                new ListSwitcher()
            }

            initializeSearchMap(ibeState, hotelForCoordinates);
        })
}

/**
 * Replaces old map button (H3) with search result map button
 */
export function injectMapButton(ibeState, hotelForCoordinates, config) {
    const oldMapButton = document.querySelector("#tuiViewSwitch > div > a:nth-child(2)");
    if (!oldMapButton) return;
    const newMapButton = createHTMLNode('a', 'btn btn-sort-list btn-sort-list--spcl');
    newMapButton.innerHTML = oldMapButton.innerHTML;

    newMapButton.addEventListener('click', (event) => {

        event.preventDefault();
        injectMapConsent({
            config, onConsentApprove: () => {
                injectSearchResultMapComponent(ibeState, hotelForCoordinates, config);
            }, overlayId: 'tui-maps-consent'
        });

        document.dispatchEvent(new CustomEvent('tui.ao.search-result-map.open-search-result-map-clicked'));
    });

    oldMapButton.replaceWith(newMapButton);
}

export default {};
